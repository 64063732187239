<template>
  <div class="page-container">
    <div v-if="showTips" class="tips-modal">
      请点击右上角按钮
      选择“在浏览器中打开”
    </div>
    <div class="logo-box">
      <img src="../../../assets/images/cn_logo.png" alt="" class="cn-logo">
    </div>
    <h2 class="sec-title">您正在扫描{{ actionList[actionType] }}二维码</h2>
    <div class="qr-box">
      <div class="qr-tips">需用MiyouApp扫描哦～</div>
      <div class="qrcode-box">
        <img src="../../../assets/images/qrcode.jpg" alt="" class="qrcode">
      </div>
      <a href="https://www.miyoulab.com/product/40490.html" target="_blank">
        <button class="download-btn">立即下载</button>
      </a>
    </div>
    <div class="miyou-desc"> <span class="my-app">【MiYou APP】</span> 一款基于区块链技术的加密邮箱</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showTips: false,
      actionType: '',
      actionList: {
        '3rdBind': '绑定',
        '3rdLogin': '登录',
        miyouRp: '领积分'
      }
    }
  },
  created() {
    this.is_weixin()
    document.title = 'MiYou邮箱'
    this.actionType = this.getQueryString('action') || ''
  },
  methods: {
    is_weixin() {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.showTips = true
      } else {
        this.showTips = false
      }
    },
    getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      let r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null
    }
  }
}
</script>
<style lang="less" scoped>
.page-container {
  background-image: url('../../../assets/images/cn_bg.png');
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;

  position: relative;
  .tips-modal {
    width: 180px;
    height: 98px;
    background-image: url('../../../assets/images/tips_modal.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c2c1c1;
    line-height: 20px;
    position: absolute;
    right: 28px;
    top: 8px;
    padding: 0 18px;
    display: flex;
    align-items: center;
  }
  .logo-box {
    text-align: center;
    margin-top: 42px;
  }
  .cn-logo {
    width: 58px;
    height: 58px;
  }
  .sec-title {
    height: 56px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 28px;
    margin-top: 20px;
    text-align: center;
    padding: 0 40px;
  }
  .qr-box {
    background-image: url('../../../assets/images/qr_block_bg.png');
    height: 450px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    overflow: hidden;
    .qr-tips {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #4d67f5;
      line-height: 20px;
      text-align: center;
      margin-top: 56px;
    }
    .qrcode-box {
      margin-top: 12px;
      .qrcode {
        width: 196px;
        height: 196px;
        // border: 1px solid #ccc;
      }
    }
    .download-btn {
      width: 300px;
      height: 40px;
      background: #4d67f5;
      border-radius: 6px;
      border: 1px solid #4d67f5;
      margin-top: 46px;

      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      cursor: pointer;
    }
  }
  .miyou-desc {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4d67f5;
    line-height: 20px;
    text-align: center;
    width: 100%;
    .my-app {
      color: #4d67f5;
    }
    position: absolute;
    bottom: 26px;
  }
}
</style>